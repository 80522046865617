import * as React from "react"
import EarningsDisclaimer from "../components/main/EarningsDisclaimer"

import Layout from "../components/layout"

import Seo from "../components/seo"

const EarningsDisclaimerPage = () => (
  <Layout>
    <Seo title="Earnings Disclaimer" />
    <EarningsDisclaimer />
  </Layout>
)

export default EarningsDisclaimerPage
