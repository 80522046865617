import React, { useState } from "react"
import styled from "styled-components"
import { Container } from "react-bootstrap"
import DisclosureFooter from "./DisclosureFooter"
import { NavigationBar } from "./NavigationBar"
import Dropdown from "./Dropdown"
import SocialFooter from "./SocialFooter"

export default function EarningsDisclaimer() {
  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => {
    setIsOpen(!isOpen)
  }

  return (
    <>
      <Dropdown isOpen={isOpen} toggle={toggle} />
      <NavigationBar toggle={toggle} />
      <section className="section-white">
        <Container>
          <ContentContainer>
            <h1>Earnings Disclaimer</h1>

            <h3 className="earnings-disclaimer__subtitle-margin">
              Earnings Disclaimer Disclaimer: No Earnings Projections, Promises
              Or Representations
            </h3>

            <p>Effective Date: May, 2018</p>

            <p>
              Every effort has been made to accurately represent this product
              and its potential.
            </p>

            <p>
              This site and the products offered on this site are not
              associated, affiliated, endorsed, or sponsored by Facebook, nor
              have they been reviewed tested or certified by Facebook.
            </p>

            <p>
              There is no guarantee that you will earn any money using the
              techniques and ideas in these materials. Examples in these
              materials are not to be interpreted as a promise or guarantee of
              earnings. Earning potential is entirely dependent on the person
              using our product, ideas, and techniques. We do not position this
              product as a “get rich scheme.”
            </p>
            <p>
              Any claims made of actual earnings or examples of actual results
              can be verified upon request. Your level of success in attaining
              the results claimed in our materials depends on the time you
              devote to the program, ideas and techniques mentioned, your
              finances, knowledge and various skills. Since these factors differ
              according to individuals, we cannot guarantee your success or
              income level. Nor are we responsible for any of your actions.
            </p>
            <p>
              Materials in our product and on our website may contain
              information that includes or is based upon forward-looking
              statements within the meaning of the securities litigation reform
              act of 1995. Forward-looking statements give our expectations or
              forecasts of future events. You can identify these statements by
              the fact that they do not relate strictly to historical or current
              facts. They use words such as “anticipate,” “estimate,” “expect,”
              “project,” “intend,” “plan,” “believe,” and other words and terms
              of similar meaning in connection with a description of potential
              earnings or financial performance.
            </p>
            <p>
              Any and all forward looking statements here or on any of our sales
              material are intended to express our opinion of earnings
              potential. Many factors will be important in determining your
              actual results and no guarantees are made that you will achieve
              results similar to ours or anybody else’s, in fact, no guarantees
              are made that you will achieve any results from our ideas and
              techniques in our material.
            </p>
            <p>
              You recognize and agree that we have made no implications,
              warranties, promises, suggestions, projections, representations or
              guarantees whatsoever to you about future prospects or earnings,
              or that you will earn any money, with respect to your purchase of
              Paul Xavier’s products, and that we have not authorized any such
              projection, promise, or representation by others.
            </p>
            <p>
              Any earnings or income statements, or any earnings or income
              examples, are only estimates of what we think you could earn.
              There is no assurance you will do as well as stated in any
              examples. If you rely upon any figures provided, you must accept
              the entire risk of not doing as well as the information provided.
              This applies whether the earnings or income examples are monetary
              in nature or pertain to advertising credits which may be earned
              (whether such credits are convertible to cash or not).
            </p>
            <p>
              There is no assurance that any prior successes or past results as
              to earnings or income (whether monetary or advertising credits,
              whether convertible to cash or not) will apply, nor can any prior
              successes be used, as an indication of your future success or
              results from any of the information, content, or strategies. Any
              and all claims or representations as to income or earnings
              (whether monetary or advertising credits, whether convertible to
              cash or not) are not to be considered as “average earnings”.
            </p>
            <p>
              (i) The Economy. The economy, both where you do business, and on a
              national and even worldwide scale, creates additional uncertainty
              and economic risk. An economic recession or depression might
              negatively affect the results produced by Paul Xavier’s products.
            </p>
            <p>
              (ii) Your Success Or Lack Of It. Your success in using the
              information or strategies provided at https://mrpaulxavier.com
              depends on a variety of factors. We have no way of knowing how
              well you will do, as we do not know you, your background, your
              work ethic, your dedication, your motivation, your desire, or your
              business skills or practices. Therefore, we do not guarantee or
              imply that you will get rich, that you will do as well, or that
              you will have any earnings (whether monetary or advertising
              credits, whether convertible to cash or not), at all.
            </p>
            <p>
              Internet businesses and earnings derived therefrom, involve
              unknown risks and are not suitable for everyone. You may not rely
              on any information presented on the website or otherwise provided
              by us, unless you do so with the knowledge and understanding that
              you can experience significant losses (including, but not limited
              to, the loss of any monies paid to purchase Paul Xavier’s
              products, and/or any monies spent setting up, operating, and/or
              marketing Paul Xavier’s products, and further, that you may have
              no earnings at all (whether monetary or advertising credits,
              whether convertible to cash or not).
            </p>
            <p>
              (iii) Forward-Looking Statements. MATERIALS CONTAINED ON THIS
              WEBSITE OR IN MATERIALS PURCHASED AND/OR DOWNLOADED FROM THIS
              WEBSITE MAY CONTAIN INFORMATION THAT INCLUDES OR IS BASED UPON
              FORWARD-LOOKING STATEMENTS WITHIN THE MEANING OF THE SECURITIES
              LITIGATION REFORM ACT OF 1995. FORWARD-LOOKING STATEMENTS GIVE OUR
              EXPECTATIONS OR FORECASTS OF FUTURE EVENTS. YOU CAN IDENTIFY THESE
              STATEMENTS BY THE FACT THAT THEY DO NOT RELATE STRICTLY TO
              HISTORICAL OR CURRENT FACTS. THEY USE WORDS SUCH AS “ANTICIPATE,”
              “ESTIMATE,” “EXPECT,” “PROJECT,” “INTEND,” “PLAN,” “BELIEVE,” AND
              OTHER WORDS AND TERMS OF SIMILAR MEANING IN CONNECTION WITH A
              DESCRIPTION OF POTENTIAL EARNINGS OR FINANCIAL PERFORMANCE.
            </p>
            <p>
              ANY AND ALL FORWARD LOOKING STATEMENTS HERE, IN OTHER MATERIALS
              CONTAINED ON THIS WEBSITE OR IN MATERIALS PURCHASED AND/OR
              DOWNLOADED FROM THIS WEBSITE ARE INTENDED TO EXPRESS OUR OPINION
              OF EARNINGS POTENTIAL. MANY FACTORS WILL BE IMPORTANT IN
              DETERMINING YOUR ACTUAL RESULTS AND NO GUARANTEES ARE MADE THAT
              YOU WILL ACHIEVE RESULTS SIMILAR TO OURS OR ANYBODY ELSE, IN FACT
              NO GUARANTEES ARE MADE THAT YOU WILL ACHIEVE ANY RESULTS FROM OUR
              IDEAS AND TECHNIQUES IN OUR MATERIAL.
            </p>
            <p>
              (iv) Due Diligence. You are advised to do your own due diligence
              when it comes to making business decisions and should use caution
              and seek the advice of qualified professionals. You should check
              with your accountant, lawyer, or professional advisor, before
              acting on this or any information. You may not consider any
              examples, documents, or other content on the website or otherwise
              provided by us to be the equivalent of professional advice.
              Nothing contained on the website or in materials available for
              sale or download on the website provides professional advice in
              any way. You should consult with your own accountant, lawyer, or
              professional advisor for any questions you may have.
            </p>
            <p>
              We assume no responsibility for any losses or damages resulting
              from your use of any link, information, or opportunity contained
              within the website or within any information disclosed by the
              owner of this site in any form whatsoever.
            </p>
            <p>
              (v) Purchase Price. Although we believe the price is fair for the
              value that you receive, you understand and agree that the purchase
              price for Paul Xavier’s products has been arbitrarily set by us.
              This price bears no relationship to objective standards.
            </p>
            <p>
              To make any of these requests, please contact our GDPR contact
              at:&nbsp;
              <a href="mailto:support@mrpaulxavier.com">
                support@mrpaulxavier.com
              </a>
              .
            </p>
          </ContentContainer>
        </Container>
      </section>
      <HorizontalLine />
      <SocialFooter />
      <DisclosureFooter />
    </>
  )
}

const ContentContainer = styled.div`
  width: 80%;
  margin-left: 10%;
  background: white;
  padding-top: 125px;
  padding-bottom: 50px;
`

const HorizontalLine = styled.div`
  width: 100vw;
  border-top: 1px solid black;
`
